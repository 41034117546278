#how-to-buy-content {
  margin-top: -150px;
  position: relative; /* Устанавливаем относительное позиционирование для контейнера */
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
}

#image-container-how-to-buy {
  margin-left: 20px; /* Отступ слева от изображения */
}

#eyes-image {
  margin-left: -40px; /* Регулируем отступ изображения, если необходимо */
  max-width: 98%;
}

#text-container {
  position: absolute; /* Абсолютное позиционирование для центрирования */
  left: 50%; /* Сдвигаем текст в середину контейнера по горизонтали */
  top: 50%; /* Сдвигаем текст в середину контейнера по вертикали */
  transform: translate(-50%, -50%); /* Центрируем текст с учетом его размера */
  margin-left: 20px; /* Отступ между изображением и текстом */
  display: flex;
  flex-direction: column;
  align-items: center;
}

#text-container p {
  text-align: center;
  margin: 0; /* Убираем отступы сверху и снизу у параграфа */
}

#youtube-link {
  display: flex;
  align-items: center;
  margin-top: 10px;
  text-decoration: none;
  color: #333; /* Цвет ссылки */
  font-family: 'Jolly Lodger', cursive;
  font-size: 36px; /* Размер шрифта */
}

#youtube-icon {
  width: 24px; /* Размер значка YouTube */
  height: 24px;
  margin-left: 8px; /* Отступ между текстом и значком */
}

#link-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px; /* Отступ между параграфом и ссылкой */
}