.content > div:first-child {
  padding-top: 70px; /* Учитываем высоту навигационного меню */
}

/* Стилизация полосы прокрутки для всего приложения */
::-webkit-scrollbar {
  width: 12px; /* Ширина полосы прокрутки */
}

::-webkit-scrollbar-track {
  background: #C5D36B; /* Цвет фона трека полосы прокрутки */
}

::-webkit-scrollbar-thumb {
  background-color: #8D9A39; /* Цвет бегунка полосы прокрутки */
  border-radius: 10px; /* Закругление углов бегунка */
  border: 3px solid #C5D36B; /* Отступы внутри бегунка */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #6C7A29; /* Цвет бегунка при наведении */
}