.section {
    padding: 20px 20px; 
    background-color: #C5D36B; /* Фон для всех разделов */
  }

.section h4 {
    text-align: center; /* Центрирование заголовков секций */
    color: #C7802D;
    font-family: 'Jolly Lodger';
    font-size: 60px; /* Увеличенный размер шрифта */
  }