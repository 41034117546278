.images-container {
    display: flex;
    justify-content: space-between; /* Располагаем изображения по краям контейнера */
    align-items: center; /* Выравниваем по центру вертикали */
    margin-top: 20px; /* Примерный отступ сверху */
  }
  
  .hit-pepe-soon{
    max-width: 28%; /* Максимальная ширина каждого изображения */
    height: auto;
    margin-top: -400px;
  }

  .soon-tokenomics {
    width: 28%; /* Максимальная ширина каждого изображения */
    height: auto;
    margin-right: -20px;
  }

  .middle-text{
    color: #333;
    text-decoration: none;
    font-family: 'Jolly Lodger', cursive;
    font-size: 36px; /* Увеличенный размер шрифта */
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }