.about-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    background-color: #C5D36B;
}

#about .about-section .about-content {
    display: flex;
    width: 80%;
    max-width: 1200px;
}

#about-p {
    color: #333;
    text-decoration: none;
    font-family: 'Jolly Lodger', cursive;
    font-size: 36px; /* Увеличенный размер шрифта */
    width: 60%; /* 100% - 20% * 2 */
    margin-left: 20%;
    margin-right: 20%;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

/*begin sqw group */
.sqw-image-container {
    position: relative; /* Установим контейнер как относительный для абсолютного позиционирования элементов внутри */
    display: flex;
    align-items: center;
}

#sqw-image {
    margin-left: 20px;
}

#about-sqw {
    position: absolute; /* Абсолютное позиционирование для центрирования по вертикали */
    left: 50%; /* Сместим текст на середину контейнера */
    transform: translateX(-50%); /* Переместим текст влево на 50% его ширины для точного центрирования */
    color: #C7802D;
    text-decoration: none;
    font-family: 'Jolly Lodger', cursive;
    font-size: 64px; /* Увеличенный размер шрифта */
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
/*end sqw group */

/*begin think-about-it group */
.think-about-it-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 150px); /* Учитываем отступ справа */
    margin-right: 150px; /* Отступ от правого края экрана */
    box-sizing: border-box; /* Включает отступы и границы в общую ширину */
}

#think-about-it-image{
    max-width: 50%; /* Максимальная ширина изображения */}

#about-think-about-it{
    flex: 1;
    max-width: 50%;
    font-family: 'Jolly Lodger', cursive;
    font-size: 36px;
    color: #333;
    margin-left: 20px; /* Отступ от изображения */
    text-align: left;
}
/*end think-about-it group */

#soon-about-image {
    position: absolute;
    right: 0px;
    max-height: 150%;
  }

.about-under-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Выровнять по левому краю */
    width: 100%;
    height: 100vh; /* Высота контейнера, можно изменить по необходимости */
    box-sizing: border-box;
    padding-left: 150px; /* Отступ слева для всех элементов */
}

.au-container-right {
    align-self: flex-end; /* Выровнять по правому краю */
    width: 53%; /* Ширина элемента */
    padding-right: 150px;
}

.au-container-left {
    width: 40%; /* Ширина элемента */
}

/*begin under-think-about-it group */
#about-under-think-about-it{
    font-family: 'Jolly Lodger', cursive;
    font-size: 36px;
    color: #333;
    text-align: left;
}
/*end think-about-it group */

#under-about-under-think-about-it {
    font-family: 'Jolly Lodger', cursive;
    font-size: 36px;
    color: #333;
    text-align: left;
    box-sizing: border-box; /* Включаем padding и border в ширину элемента */
  }