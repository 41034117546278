.random-section {
    position: relative;
    width: 100%;
  }
  
  .paragraph {
    position: absolute;
    width: 35%; /* Увеличенная ширина абзацев */
    font-size: 16px;
    line-height: 1.5;
  }
  
  .paragraph p {
    color: #333;
    text-decoration: none;
    font-family: 'Jolly Lodger', cursive;
    font-size: 38px; /* Увеличенный размер шрифта */
  }
  
  .paragraph ul {
    color: #333;
    text-decoration: none;
    font-family: 'Jolly Lodger', cursive;
    font-size: 38px; /* Увеличенный размер шрифта */
    list-style-type: none;
    padding: 0;
  }
  
  .paragraph ul li {
    margin-bottom: 10px; /* Отступ между пунктами списка */
  }
  
  .p1 {
    top: -50px;
    left: 12%;
  }
  
  .p2 {
    top: 50px;
    right: 5%;
  }
  
  .p3 {
    top: 400px; /* Опущен ниже */
    left: 45%; /* Сдвинут ближе к центру */
    transform: translateX(-50%);
  }
  
  .p4 {
    top: 700px; /* Опущен ниже */
    left: 10%;
  }
  
  .image-container-roadmap {
    position: absolute;
    top: 480px;
    right: 24%; /* Сдвинуто ближе к центру */
    transform: translateX(50%);
  }
  
  #baby-soon {
    width: 100%; /* Ширина изображения */
    height: auto;
  }
  
  .p5 {
    top: 800px; /* Опущен ниже */
    right: 10px;
  }
