.footer {
  padding: 20px 20px;
  background-color: #C5D36B; /* Фон для всех разделов */
  text-align: center;
  font-family: 'Jolly Lodger', cursive;
  color: #333;
  position: relative;
}

.footer-separator {
  height: 1.5px;
  background-color: #8D9A39;
  margin: 0 10%;
}

.footer-content p {
  margin: 5px 0;
}

.footer-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.left-image {
  position: absolute;
  bottom: 0px;
  max-width: 22%;
  height: auto;
}

.social-icons {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.social-icons a {
  margin: 0 10px;
}

.social-icons img {
  width: 55px; /* Размер иконок соц. сетей */
  height: 55px;
}

.right-image {
  position: absolute;
  margin-bottom: 20px;
  right: 20px;
  max-width: 22%;
  height: auto;
}

.footer-bottom-text p{
  margin-bottom: -10px;
  font-size: 25px;
  text-align: center;
}
