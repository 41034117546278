.home-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  background-color: #C5D36B;
  min-height: 100vh;
}

#home .home-section .content {
  display: flex;
  width: 80%;
  max-width: 1200px;
}

p {
  color: #333;
  text-decoration: none;
  margin-left: 20px;
  font-family: 'Jolly Lodger', cursive;
  font-size: 38px; /* Увеличенный размер шрифта */
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
  padding-top: 40px;
}

.image-container img {
  max-width: 60%;
  height: auto;
  border-radius: 10px; /* Слегка закруглим углы */
}
