@import url('https://fonts.googleapis.com/css2?family=Jolly+Lodger&display=swap');

.navbar {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  align-items: center;
  background: #C5D36B;
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  border-bottom: 3px solid transparent; /* Для создания отступа для линии */
}

.navbar .nav-links-container {
  display: flex;
  justify-content: center;
  width: 60%; /* Центральная часть навбара */
}

.navbar .nav-links {
  display: flex;
  gap: 35px; /* Расстояние между ссылками */
  justify-content: center;
}

.navbar .logo {
    position: absolute;
    left: 10px;
    display: flex;
    align-items: center;
  }
  
  .navbar .logo img {
    height: 60px; /* Высота изображения */
    margin-left: 10px; /* Отступ между изображением и текстом */
  }

  .navbar .icons {
    position: absolute;
    right: 60px;
    gap: 5px; /* Расстояние между иконками */
    margin-right: 5px; /* Отступ для крайне правой иконки */
    cursor: pointer;
  }

.navbar .icons img {
  width: 40px; /* Размер иконок соц. сетей */
  height: 40px;
  margin: 10px;
}

.navbar::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 10%;
  right: 10%;
  height: 1.5px;
  background-color: #8D9A39;
}

.nav-link {
  cursor: pointer;
  color: #C7802D;
  text-decoration: none;
  font-family: 'Jolly Lodger', cursive;
  font-size: 40px; /* Увеличенный размер шрифта */
}

.nav-link:hover {
  text-decoration: underline;
}
